import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/MainPage";
import FeedbackForm from "./components/FeedbackForm";
import FeedbackSuccessPage from "./components/FeedbackSuccessPage";
import ThemeProviderComponent from "./components/ThemeProviderComponent";
import NotFound from "./components/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import DevicesPage from "./pages/Devices";

function App() {
  return (
    // <div className="App">
    //     <MainPage/>
    // </div>

    <Router>
      <ThemeProviderComponent>
      <div className="App">
        <Routes>
        <Route path="/" element={<MainPage/>} />
        <Route path="/devices" element={<DevicesPage />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/feedback" element={<FeedbackForm/>} />
          <Route path="/feedbackSuccess" element={<FeedbackSuccessPage />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsOfUse" element={<TermsOfUse />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      </ThemeProviderComponent>
    </Router>
  );
}

export default App;
