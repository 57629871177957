import * as React from 'react';
import Divider from '@mui/material/Divider';
import Hero from '../deviceComponents/Hero';
import FAQ from '../deviceComponents/FAQ';
import Footer from '../deviceComponents/Footer';
import Languages from '../components/Languages';
import BuyNow from '../deviceComponents/BuyNow';
import ProductDetails from '../components/ProductDetails';
import LaunchingSoon from '../components/LaunchingSoon';


export default function DevicesPage() {
 
  return (
    <div>
      <Hero />
      <div>
        
        <LaunchingSoon />

        <Divider />
        <ProductDetails />
        <Divider />
        <BuyNow/>
        <FAQ />
        <Divider />
        <Languages/>
        <Divider />
        <Footer />
      </div>
    </div>
  );
}
