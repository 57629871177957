import * as React from "react";

import FleetControlHero from "../fleetControl/FleetControlHero";
import { Divider } from "@mui/material";
import BookDemo from "../fleetControl/BookDemo";
import FleetControlFooter from "../fleetControl/FleetControlFooter";
import FleetControlDetails from "../fleetControl/FleetControlDetails";
// import FleetControlFAQ from "../fleetControl/FleetControlFAQ";
import Pricing from "../fleetControl/Pricing";

export default function MainPage() {
  return (
    <div>
      <FleetControlHero />
      <div>
        {/* <LaunchingSoon /> */}

        <FleetControlDetails />
        {/* <Divider variant="middle"/>
        <FleetControlFAQ/> */}
        <Divider variant="middle"/>
        <Pricing/>
        <BookDemo />
        <FleetControlFooter />
      </div>
    </div>
  );
}
