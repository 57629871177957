import React from "react";
import { Container, Typography, Box } from "@mui/material";

export default function TermsOfUse() {
  return (
    <Container maxWidth="md" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Terms of Use
      </Typography>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography paragraph>
          Effective Date:{" "}
          <span style={{ textDecoration: "underline" }}>1 December 2024</span>
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography>
          Welcome to Oldcrux Private Limited. These Terms of Use ("Terms")
          govern your use of our fleet control system software (the "Service").
          By accessing or using the Service, you agree to these Terms.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">1. Use of the Service</Typography>
        <Typography>
          1.1. The Service is provided on a Software-as-a-Service (SaaS) basis.
          Organizations are granted a limited, non-exclusive, and
          non-transferable right to use the Service for their internal business
          purposes only.
        </Typography>
        <Typography>
          1.2. You agree not to:
          <ul>
            <li>
              Reverse-engineer, decompile, or otherwise attempt to derive the
              source code of the Service.
            </li>
            <li>Use the Service for unlawful purposes.</li>
            <li>
              Share or resell access to the Service without prior written
              consent.
            </li>
          </ul>
        </Typography>
      </Box>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">2. User Responsibilities</Typography>
        <Typography>
          2.1. Organizations are solely responsible for managing their data,
          including but not limited to:
          <ul>
            <li>Uploading accurate and lawful information.</li>
            <li>Safeguarding user credentials.</li>
            <li>Backing up any generated reports or metrics.</li>
          </ul>
        </Typography>
        <Typography>
          2.2. Oldcrux Private Limited is not liable for any loss, corruption,
          or unauthorized access to data uploaded or generated using the
          Service.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">3. Intellectual Property</Typography>
        <Typography>
          All intellectual property rights in the Service, including but not
          limited to software, design, and branding, are owned by Oldcrux
          Private Limited. Use of the Service does not grant you ownership of
          any intellectual property rights.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">4. Disclaimer of Warranties</Typography>
        <Typography>
          The Service is provided "as is" and "as available" without warranties
          of any kind, express or implied, including but not limited to
          merchantability, fitness for a particular purpose, or
          non-infringement.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">5. Limitation of Liability</Typography>
        <Typography>
          To the fullest extent permitted by law, Oldcrux Private Limited is not
          liable for any indirect, incidental, consequential, or punitive
          damages arising out of or related to your use of the Service.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">6. Termination</Typography>
        <Typography>
          We reserve the right to suspend or terminate your access to the
          Service at our sole discretion if you breach these Terms or engage in
          unlawful or unauthorized use of the Service.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">7. Changes to These Terms</Typography>
        <Typography>
          We may update these Terms from time to time. Changes will be posted on
          this page, and the "Effective Date" will be updated accordingly.
          Continued use of our Service after any changes indicates your
          acceptance of the updated Terms.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">8. Governing Law</Typography>
        <Typography>
          These Terms are governed by the laws of district court of Bhubaneswar,
          Odisha. Any disputes arising under these Terms shall be resolved
          exclusively in the courts of district court of Bhubaneswar, Odisha.
        </Typography>
      </Box>
      <Box sx={{ textAlign: "left", mb: 2 }}>
        <Typography variant="h6">9. Contact Us</Typography>
        <Typography>
          If you have any questions about these Terms, please contact us at:
        </Typography>
      </Box>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography>support@oldcrux.com</Typography>
        <Typography>
          Plot-2305/11962 Saptasati Nagar, Mancheswar, Bhubaneswar, Odisha,
          751017
        </Typography>
      </Box>
    </Container>
  );
}
