import React from "react";
import { Container, Typography, Box } from "@mui/material";

export default function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Privacy Policy
      </Typography>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography paragraph>
          Effective Date:{" "}
          <span style={{ textDecoration: "underline" }}>1 December 2024</span>
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography>
          Your privacy is important to us at Oldcrux Private Limited. This
          Privacy Policy outlines how we handle the information provided by
          organizations using our fleet control system software (the "Service"). By
          using our Service, you agree to the practices described in this
          Privacy Policy.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">1. Information We Do Not Collect</Typography>
        <Typography>
          Our software does not use any personal or organizational data uploaded
          by organizations for any purposes outside of providing the Service.
          This includes, but is not limited to:
        </Typography>
        <Typography>
          <ul>
            <li>Vehicle information uploaded by organizations.</li>
            <li>User details input by organizations.</li>
            <li>
              Reports, metrics, or any data generated through the Service.
            </li>
          </ul>
        </Typography>
        <Typography>
          All data uploaded or generated within the Service is stored on our
          servers solely for the purpose of providing the Service. We do not
          access or use this data for any personal or business purposes.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">2. Responsibility for Data</Typography>
        <Typography>
          Organizations using the Service are fully responsible for:
          <ul>
            <li>Managing their data securely.</li>
            <li>
              Ensuring compliance with applicable data protection laws and
              regulations.
            </li>
            <li>
              Backing up and safeguarding any data uploaded to or generated by
              the Service.
            </li>
          </ul>
        </Typography>
        <Typography>
          Oldcrux Private Limited is not liable for any loss, corruption, or
          unauthorized access to data uploaded or generated using the Service.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">3. Cookies and Analytics</Typography>
        <Typography>
          We may use cookies and analytics tools to monitor and improve the
          functionality of our website. These tools collect general information,
          such as:
          <ul>
            <li>Website usage statistics.</li>
            <li>Browser and device information.</li>
            <li>IP addresses (anonymized).</li>
          </ul>
          This information is used solely to enhance user experience and ensure
          the performance of our website.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">4. Third-Party Services</Typography>
        <Typography>
          Our Service may include links to third-party websites or services. We
          are not responsible for the privacy practices of these third parties.
          Please review their privacy policies before providing any personal
          information.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">5. Security</Typography>
        <Typography>
          We implement reasonable measures to protect our systems from
          unauthorized access or malicious attacks. While data resides on our
          servers, organizations retain responsibility for managing their data
          securely within the Service.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography variant="h6">6. Changes to This Privacy Policy</Typography>
        <Typography>
          We may update this Privacy Policy from time to time. Changes will be
          posted on this page, and the "Effective Date" will be updated
          accordingly. Continued use of our Service after any changes indicates
          your acceptance of the updated Privacy Policy.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", mb: 2 }}>
        <Typography variant="h6">7. Contact Us</Typography>
        <Typography>
          If you have any questions about these Terms, please contact us at:
        </Typography>
      </Box>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography>support@oldcrux.com</Typography>
        <Typography>
          Plot-2305/11962 Saptasati Nagar, Mancheswar, Bhubaneswar, Odisha,
          751017
        </Typography>
      </Box>
    </Container>
  );
}
