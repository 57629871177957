import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Stack } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {`© ${new Date().getFullYear()} `}
      <Link color="text.secondary" href="https://oldcrux.com/">
        OldCrux Pvt Ltd.
      </Link>
      {` All rights reserved`}
    </Typography>
  );
}

export default function FleetControlFooter() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 2, sm: 5 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { sm: "center", md: "space-between" },
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          useFlexGap
          sx={{
            justifyContent: "left",
            color: "text.secondary",
            alignItems: { xs: "center" },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              flexDirection: "row",
              gap: 1,
            }}
          >
            <EmailOutlinedIcon
              sx={{ fontSize: "large", color: "text.secondary" }}
            />
            <Typography
              variant="body2"
              sx={{ fontWeight: "medium", color: "text.secondary" }}
            >
              <Link
                color="text.secondary"
                variant="body2"
                href="mailto:sales@oldcrux.com"
              >
                sales@oldcrux.com
              </Link>
            </Typography>
          </Box>
          <Link
            component={RouterLink}
            color="text.secondary"
            variant="body2"
            to="/feedback"
          >
            Contact Us
          </Link>
        </Stack>

        <div>
          <Link
            component={RouterLink}
            color="text.secondary"
            variant="body2"
            to="/privacyPolicy"
          >
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;*&nbsp;
          </Typography>
          <Link
            component={RouterLink}
            color="text.secondary"
            variant="body2"
            to="/termsOfUse"
          >
            Terms of Use
          </Link>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}
