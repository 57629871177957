import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

const handleClick = () => {
  // Navigate to a new URL (e.g., "/dashboard")
  window.location.href = "https://fleetcontrol.oldcrux.com"; // Replace with the desired URL
};

export default function LoginButton() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 70,
        right: 15,
        zIndex: 1000, // Ensure it's above other elements
      }}
    >
      <Button
        variant="contained"
        sx={{ color: "blue" }}
        onClick={handleClick}
      >
        Login
      </Button>
    </Box>
  );
}
