import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ImageList, ImageListItem, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled("div")(({ theme, backgroundImage }) => ({
  alignSelf: "center",
  padding: "1%",
  position: "relative",
  marginTop: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${backgroundImage || ""})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundColor: "#001629",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(5),
    height: "300px",
    width: "50%",
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

const itemData = [
  { img: "/assets/laptop-dashboard.png", title: "laptop" },
  { img: "/assets/mobile-dashboard.png", title: "mobile" },
];

export default function FleetControlDetails() {
  return (
    <Container
      id="FleetControlDetails"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 12 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "85%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <ImageList
          sx={{
            width: { sm: "100%", md: "100%" },
            height: { sm: "100%", md: "100%" },
          }}
          variant="masonry"
          cols={2}
          gap={4}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=161&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{ color: "text.primary", fontWeight: "300" }}
        >
          A minimalistic responsive design that ensures a seamless experience
          across both large screens and mobile devices, eliminating the need for
          a separate mobile app
        </Typography>
      </Box>

      <Box
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 12 },
          textAlign: { sm: "left", md: "center" },
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={4}
          sx={{ padding: 2 }}
        >
          <StyledBox>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/fSPYTQs9eP0"
              title="Geofence Live Tracking"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                border: "none",
              }}
            />
          </StyledBox>

          <Box sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{ color: "text.primary", fontWeight: "300" }}
            >
              Interactively create and maintain geofences on the map. <br />
              Assign geofences to your vehicles and see the vehicles covering
              the geofences in real time
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ flex: 1, textAlign: "center" }}>
        <Typography
          variant="h6"
          sx={{ color: "text.primary", fontWeight: "300" }}
        >
          Live Track Vehicles. View today's travel path or analyze historical
          data. Generate Vehicle performance Report and many more.
        </Typography>
      </Box>
    </Container>
  );
}
