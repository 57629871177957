import { Box, Container, Stack, Typography } from "@mui/material";

export default function BookDemo() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 4 },
        py: { xs: 8, sm: 4 },
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", sm: "flex" },
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: "text.primary", fontWeight: "300" }}
        >
          Interested to know more? Book a demo today
          {" "}<a
            href="https://docs.google.com/forms/d/e/1FAIpQLScoMRcX6bc4rMAolZtpAE93gV3mOw-e4szK6Fh0DX2PjU4Qmw/viewform?usp=dialog"
            // onClick={handleOpen}
            style={{ color: "white", textDecoration: "underline" }}
          >
            here
          </a>{" "}
        </Typography>

        <Stack
          direction="row"
          spacing={6}
          useFlexGap
          sx={{ justifyContent: "center" }}
        >
        </Stack>
      </Box>
    </Container>
  );
}
